import i18next from "i18next";
import { useContext, createContext, useState, useEffect } from "react";
import { loadPersistedData, persistData } from "../utils/persistor";
import cookieService from "../services/cookie";


const context = createContext({
    main: {
        theme: "dark", setTheme: () => { },
        lang: "fa", setLang: () => { }
    },
    profile: {
        token: null, setToken: () => { }
    }
})

export const useMainContext = () => {
    return useContext(context)
}


const MainContextProvider = ({ children }) => {

    const [theme, _setTheme] = useState(loadPersistedData('theme') ? loadPersistedData('theme') : "dark")
    const [lang, setLang] = useState(loadPersistedData('lang') ? loadPersistedData('lang') : "fa")

    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [token, _setToken] = useState(cookieService.get('token'))
    const [sidebar, setSidebar] = useState(true)

    const [lotp, _setLandingToken] = useState(cookieService.get('lotp'))

    const [colorPref, _setColorPref] = useState(loadPersistedData('color-pref') ? loadPersistedData('color-pref') : 'classic')

    const [needPackage, setNeedPackage] = useState(false)

    const setColorPref = (pref) => {
        persistData('color-pref', pref)
        _setColorPref(pref)
    }

    useEffect(() => {
        persistData('theme', theme)
        if (theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [theme])

    useEffect(() => {
        i18next.changeLanguage(lang)
        persistData('lang', lang)
    }, [lang])

    const setToken = (value) => {
        cookieService.set('token', value, { url: '/', expires: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000) })
        _setToken(value)
    }

    const setTheme = (_theme) => {
        persistData('theme', _theme)
        _setTheme(_theme)
    }

    const setLOTP = (t) => {
        cookieService.set('lotp', t, { url: '/', expires: new Date(Date.now() + 2 * 60 * 60 * 1000) })
        _setLandingToken(t)
    }

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }, [theme])

    return (
        <context.Provider value={{
            main: {
                theme, setTheme,
                lang, setLang,
                sidebar, setSidebar,
            },
            profile: {
                token, setToken,
                isSuperAdmin, setIsSuperAdmin,
                lotp, setLOTP
            },
            colorPref, setColorPref,
            needPackage, setNeedPackage
        }}>
            {children}
        </context.Provider>
    )
}

export default MainContextProvider;
